import * as React from 'react'

const StarIcon = ({
    fill = '#3C3C3C',
    className = '',
    height = '10px',
    width = '10px',
    stroke = '#3C3C3C',
} = {}): React.ReactElement => (
    <svg className={className} width={width} height={height} viewBox="0 0 20 19" fill="none" stroke={stroke}>
        <path
            d="M9.77295 0L12.0181 6.90983H19.2835L13.4057 11.1803L15.6508 18.0902L9.77295 13.8197L3.8951 18.0902L6.14024 11.1803L0.262384 6.90983H7.52781L9.77295 0Z"
            fill={fill}
        />
    </svg>
)

export default StarIcon
