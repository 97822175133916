import * as React from 'react'

const CabinetTokenIcon = ({ className = '', fill = 'none' } = {}): React.ReactElement => (
    <svg
        className={className}
        width="0"
        height="0"
        viewBox="0 0 276 275"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <clipPath id="token-clip-path" clipPathUnits="objectBoundingBox">
            <path d="M0.499648 0.999986C0.699204 1.00068 0.900871 0.975318 0.938178 0.922507C1.01561 0.812369 1.02546 0.170998 0.938178 0.0719784C0.893832 0.0219473 0.696388 -0.00133108 0.499648 5.86755e-05H0.500704C0.303612 -0.00133108 0.106168 0.0219473 0.0618224 0.0719784C-0.0254612 0.170651 -0.0156066 0.812369 0.0618224 0.922507C0.0991291 0.975318 0.300796 1.00068 0.500352 0.999986H0.499648Z" />
        </clipPath>
    </svg>
)

export default CabinetTokenIcon
