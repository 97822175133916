const DEFAULTS = {
    dateTimeFormattingOptions: {
        dateStyle: 'long',
    } as Intl.DateTimeFormatOptions,
    locale: 'en-US',
}

/**
 * Returns date string formatted to a specific locale
 * @param date Date
 * @param formattingOptions Intl date formatting options
 * @param locale string
 * @returns string
 */
export const getFormattedDate = (
    date: Date,
    formattingOptions?: Intl.DateTimeFormatOptions,
    locale?: string,
    useOrdinals = true,
): string => {
    // formats to Month Day, Year
    const dateFormatter = new Intl.DateTimeFormat(
        locale || DEFAULTS.locale,
        formattingOptions ?? DEFAULTS.dateTimeFormattingOptions,
    )

    let dateString: string

    if (!isNaN(date.valueOf())) {
        try {
            dateString = dateFormatter.format(date)
        } catch (e) {
            // date formatter isn't supported
            dateString = date.toLocaleDateString()
        }
    }

    if (dateString && useOrdinals) {
        // get date string with ordinal date
        try {
            const dayOfMonth = date.getDate()
            dateString = dateString.replace(dayOfMonth.toString(), getOrdinalNumber(dayOfMonth))
        } catch (e) {
            console.error(e)
        }
    }

    return dateString
}

/**
 * Returns ordinal number string
 * @param number Number
 * @param locale String
 * @returns String
 */
export const getOrdinalNumber = (number: number, locale?: string): string => {
    try {
        const pr = new Intl.PluralRules(locale || DEFAULTS.locale, { type: 'ordinal' })

        const suffixes = new Map([
            ['one', 'st'],
            ['two', 'nd'],
            ['few', 'rd'],
            ['other', 'th'],
        ])

        const rule = pr.select(number) // returns one, two, few or other
        const suffix = suffixes.get(rule)

        return `${number}${suffix}`
    } catch {
        // return just number if there's an exception
        // **
        // IE will throw an exception, it doesn't have
        // support for Intl.PluralRules
        return number.toString()
    }
}

/**
 * takes seconds and returns time in hh:mm:ss format
 * @param seconds number
 * @returns string
 */
export const formatTimeToHHMMSS = (seconds: number): string => {
    if (!seconds) {
        return '0:00'
    }

    const dateObj = new Date(seconds * 1000)

    const justSeconds = dateObj.getUTCSeconds()
    const minutes = dateObj.getUTCMinutes()
    const hours = dateObj.getUTCHours()

    let timeString = ''

    if (hours > 0) {
        timeString += hours + ':'
    }

    timeString += minutes + ':' + justSeconds.toString().padStart(2, '0')

    return timeString
}
