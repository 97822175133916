import * as React from 'react'

const PlusIcon = ({
    className = '',
    pathClassName = '',
    width = 8,
    height = 8,
    fill = '#a8a8a8',
} = {}): React.ReactElement => (
    <svg className={className} width={width} height={height} viewBox="0 0 8 8" fill="none">
        <path
            className={pathClassName}
            d="M8 4.88889H4.88889V8H3.11111V4.88889H0V3.11111H3.11111V0H4.88889V3.11111H8V4.88889Z"
            fill={fill}
        />
    </svg>
)

export default PlusIcon
