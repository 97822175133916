import * as React from 'react'

const MinusIcon = ({
    className = '',
    pathClassName = '',
    height = 3,
    width = 9,
    fill = '#A8A8A8',
} = {}): React.ReactElement => (
    <svg className={className} width={width} height={height} viewBox="0 0 9 3" fill="none">
        <path className={pathClassName} d="M0.5 2.3891L0.5 0.611327L8.5 0.611328L8.5 2.38911L0.5 2.3891Z" fill={fill} />
    </svg>
)

export default MinusIcon
