import * as React from 'react'
import CabinetTokenIcon from 'icons/cabinet-token-icon'
import styles from './style.module.css'
import { colors } from 'theme.cjs'

interface IProps {
    children: React.ReactNode
    className?: string
    wrapperClassName?: string
    borderColor?: string // theme color
    borderWidth?: string // css unit
}

const CabinetTokenMask = ({
    children,
    className = '',
    wrapperClassName = '',
    borderWidth,
    borderColor = colors.mineshaft,
}: IProps): React.ReactElement => {
    return (
        <div
            className={`w-full relative ${wrapperClassName} ${borderWidth ? styles['clip-path-border'] : ''}`}
            style={
                borderWidth && {
                    ['--clip-border-width' as string]: borderWidth,
                    ['--clip-border-color' as string]: borderColor,
                }
            }
        >
            {/* {borderClassName && <div className={`${borderClassName} absolute top-0 left-0 token-shape-crop`} />} */}
            <div className={`token-shape-crop w-full h-full ${className}`}>{children}</div>
            <CabinetTokenIcon className="absolute top-0 left-0 -z-10" />
        </div>
    )
}

export default CabinetTokenMask
