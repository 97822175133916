import * as React from 'react'
import styles from './style.module.css'

interface IProps {
    className?: string
    label: string
}

const RangeSlider = ({
    className,
    label,
    ...props
}: IProps & React.ComponentPropsWithoutRef<'input'>): React.ReactElement => (
    <input aria-label={label} className={`${styles['input-range']} ${className ?? ''}`} type="range" {...props} />
)

export default RangeSlider
