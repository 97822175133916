// 2.0 skus
const JAR_POUCH_VARIANT_IDENTIFIER_SKU_V2 = 'CAB-PJ-' // sample: CAB-PJ-30M-10016
const POUCH_VARIANT_IDENTIFIER_SKU_V2 = 'CAB-P-[0-9]+-[0-9]+' // sample: CAB-P-30-10016

export const JAR_POUCH_VARIANT_REGEXP_V2 = new RegExp(JAR_POUCH_VARIANT_IDENTIFIER_SKU_V2)
export const POUCH_VARIANT_REGEXP_V2 = new RegExp(POUCH_VARIANT_IDENTIFIER_SKU_V2)

// 3.0 skus
const JAR_PILLS_VARIANT_IDENTIFIER_SKU_V3 = 'CAB-J-[0-9]+[A-Z]-[0-9]+[A-Z]' // sample: CAB-J-30M-10016A
const POUCH_VARIANT_IDENTIFIER_SKU_V3 = 'CAB-P-[0-9]+-[0-9]+[A-Z]' // sample: CAB-P-30-10016A

const RECYCLING_BAG_IDENTIFIER_IN_SKU_V3 = 'CAB-POUCH-[A-Z]'

const JAR_PILLS_VARIANT_REGEXP_V3 = new RegExp(JAR_PILLS_VARIANT_IDENTIFIER_SKU_V3)
const POUCH_VARIANT_REGEXP_V3 = new RegExp(POUCH_VARIANT_IDENTIFIER_SKU_V3)
const RECYCLING_BAG_REGEXP_V3 = new RegExp(RECYCLING_BAG_IDENTIFIER_IN_SKU_V3)

export const JAR_PILL_VARIANT_REGEXP = JAR_PILLS_VARIANT_REGEXP_V3
export const POUCH_VARIANT_REGEXP = POUCH_VARIANT_REGEXP_V3
export const RECYCLING_BAG_REGEXP = RECYCLING_BAG_REGEXP_V3

// matches first occurence of number
export const FIRST_NUMBER_IDENTIFIER_REGEXP = /([0-9])+/
