// we need to seperate bg and border instead of just colors because
// this ends up being a className, on build, dynamic classes will end
// up getting purged

/**
 * If we know the Collection Page href, use that to map to color.
 * These traditionally live in the navgataion model where
 *
 *      Contentful.Navigation.title === 'Shop'
 *
 * but they could live elsewhere.
 * See Navigation model and IContentfulLink for format
 */

export const hrefToBackgroundColor = {
    '/collections': `bg-black`,
    '/collections/allergy-relief': `bg-allergyRelief`,
    '/collections/pain-relief': `bg-painFever`,
    '/collections/sleep-stress': `bg-sleepStress`,
    '/collections/stomach-relief-solutions': `bg-digestiveHealth`,
    '/collections/cold-medicine': `bg-coldFlu`,
}

/**
 * If we know the Medicine Category name, use that to map to color.
 * These keys map to Contentful.medicineCategory.name
 */
export const nameToBackgroundColor = {
    'Shop All': `bg-transparent`,
    All: `bg-black`,
    'Allergy Relief': `bg-allergyRelief`,
    'Pain Relief': `bg-painFever`,
    'Sleep & Stress': `bg-sleepStress`,
    'Digestive Health': `bg-digestiveHealth`,
    'Cold & Flu': `bg-coldFlu`,
    Rx: `bg-gray`,
    'Mental Health Rx': 'bg-black',
}

export const nameToBackgroundColorWithHover = {
    'Shop All': `hover:bg-black`,
    'Allergy Relief': `hover:bg-allergyRelief`,
    'Pain Relief': `hover:bg-painFever`,
    'Sleep & Stress': `hover:bg-sleepStress`,
    'Digestive Health': `hover:bg-digestiveHealth`,
    'Cold & Flu': `hover:bg-coldFlu`,
    'Mental Health Rx': 'hover:bg-black',
}

export const hrefToBorderColor = {
    '/collections': `border-black`,
    '/collections/allergy-relief': `border-allergyRelief`,
    '/collections/pain-relief': `border-painFever`,
    '/collections/sleep-stress': `border-sleepStress`,
    '/collections/stomach-relief-solutions': `border-digestiveHealth`,
    '/collections/cold-medicine': `border-coldFlu`,
}

export const nameToBorderColor = {
    'Shop All': `border-black`,
    'Allergy Relief': `border-allergyRelief`,
    'Pain Relief': `border-painFever`,
    'Sleep & Stress': `border-sleepStress`,
    'Digestive Health': `border-digestiveHealth`,
    'Cold & Flu': `border-coldFlu`,
    'Mental Health Rx': `border-black`,
}

export const hrefToRingColor = {
    '/collections': `ring-black`,
    '/collections/allergy-relief': `ring-allergyRelief`,
    '/collections/pain-relief': `ring-painFever`,
    '/collections/sleep-stress': `ring-sleepStress`,
    '/collections/stomach-relief-solutions': `ring-digestiveHealth`,
    '/collections/cold-medicine': `ring-coldFlu`,
}

export const nameToRingColor = {
    'Shop All': `ring-black`,
    'Allergy Relief': `ring-allergyRelief`,
    'Pain Relief': `ring-painFever`,
    'Sleep & Stress': `ring-sleepStress`,
    'Digestive Health': `ring-digestiveHealth`,
    'Cold & Flu': `ring-coldFlu`,
}

// uses product varaint of theme colors
export const nameToProductBackgroundColor = {
    'Shop All': `bg-transparent`,
    'Allergy Relief': `bg-allergyRelief`,
    'Pain Relief': `bg-painFever`,
    'Sleep & Stress': `bg-sleepStress`,
    'Digestive Health': `bg-digestiveHealth`,
    'Cold & Flu': `bg-coldFlu`,
}
