import * as React from 'react'
import Carousel from 'components/carousel'
import RangeSlider from 'components/range-slider/range-slider'
import ArrowRightIcon from 'icons/arrow-right'
import { colors } from 'theme.cjs'
import { IconButton } from 'components/buttons/button'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import globalManifest from 'data/global-manifest.json'

const { resources: uiResources } = globalManifest

interface IProps {
    children: React.ReactNode
    rangeSliderLabel: string
    itemWidth: number
    responsive?: Record<string, unknown>[]
    rangeClassName?: string
    snap?: boolean
    showArrows?: boolean
    carouselWrapperClassname?: string
}

// scale range input by this factor
// this is done to ensure smooth animation
// of the slider
const rangeScale = 100

const CarouselWithSlider = ({
    children,
    rangeSliderLabel,
    itemWidth,
    rangeClassName,
    responsive,
    snap,
    showArrows,
    carouselWrapperClassname,
}: IProps): React.ReactElement => {
    const [range, setRange] = React.useState(0)
    const [doesCarouselOverflow, setDoesCarouselOverflow] = React.useState(true)

    const isDraggingRef = React.useRef(false)
    const isMountedRef = React.useRef(false)

    const carouselRef = React.useRef(null)

    React.useEffect(() => {
        if (isMountedRef.current) {
            // start from the first slide when slides change
            setRange(0)
            carouselRef.current?.scrollItem(0)

            carouselRef.current?.refresh(true)
        }

        isMountedRef.current = true
    }, [children])

    useIsomorphicLayoutEffect(() => {
        const checkIfCarouselHasScroll = () => {
            return carouselRef.current?.ele?.scrollWidth > carouselRef.current?.ele?.offsetWidth
        }

        // only show range input when carousel has scroll
        setDoesCarouselOverflow(checkIfCarouselHasScroll())

        let timer: NodeJS.Timeout

        const resizeEventListener = () => {
            clearTimeout(timer)

            // debounce
            timer = setTimeout(() => {
                setDoesCarouselOverflow(checkIfCarouselHasScroll())
            }, 300)
        }

        window.addEventListener('resize', resizeEventListener)

        return () => {
            window.removeEventListener('resize', resizeEventListener)
            clearTimeout(timer)
        }
    }, [])

    // update range slider value when carousel is
    // dragged/scrolled
    const onCarouselScroll = React.useCallback((e) => {
        // if (typeof caroulseItemsLength === 'number') {
        // if user is dragging the slider, don't update
        if (isDraggingRef.current) return

        const scrollLeft = e.currentTarget.scrollLeft
        const clientWidth = e.currentTarget.clientWidth
        const scrollWidth = e.currentTarget.scrollWidth

        // range is ratio of current horizontal scroll over available horizontal scroll
        // ** multiply with caroulseItemsLength because max value of range is caroulseItemsLength
        const range = (scrollLeft / (scrollWidth - clientWidth)) * rangeScale
        setRange(range)
        // }
    }, [])

    const rangeOnChangeHandler = (e) => {
        const draggedRange = Number(e.target.value)
        setRange(draggedRange)

        const clientWidth = carouselRef.current?.ele?.clientWidth
        const scrollWidth = carouselRef.current?.ele?.scrollWidth

        // scroll left is range multiplied by total horizontal scroll
        // ** divide by caroulseItemsLength as range max value is caroulseItemsLength
        const scrollLeft = (draggedRange * (scrollWidth - clientWidth)) / rangeScale
        carouselRef.current?.scrollTo(scrollLeft)
    }

    return (
        <>
            <div className={`relative ${carouselWrapperClassname ?? ''}`}>
                <Carousel
                    onScroll={onCarouselScroll}
                    itemWidth={itemWidth}
                    exactWidth={true}
                    slidesToShow="auto"
                    ref={carouselRef}
                    responsive={responsive}
                    nextArrow=".next-arrow"
                    prevArrow=".prev-arrow"
                    snap={snap}
                >
                    {children}
                </Carousel>
                {showArrows && doesCarouselOverflow && (
                    <>
                        {/* carousel navigation arrows */}
                        <IconButton
                            label={uiResources?.previousLabel?.value}
                            className="hidden md:flex prev-arrow absolute top-1/2 left-5 md:left-8 xl:left-15 -translate-y-1/2 bg-white h-12 w-12 border border-gray justify-center items-center"
                        >
                            <ArrowRightIcon
                                stroke={colors.mineshaft}
                                height="15"
                                width="7"
                                strokeWidth={2}
                                className="rotate-180"
                            />
                        </IconButton>
                        <IconButton
                            label={uiResources?.nextLabel?.value}
                            className="hidden md:flex next-arrow absolute top-1/2 right-5 md:right-8 xl:right-15 -translate-y-1/2 rounded-full bg-white h-12 w-12 border border-gray justify-center items-center"
                        >
                            <ArrowRightIcon stroke={colors.mineshaft} height="15" width="7" strokeWidth={2} />
                        </IconButton>
                    </>
                )}
            </div>
            {doesCarouselOverflow && (
                <RangeSlider
                    label={rangeSliderLabel}
                    onMouseDown={() => (isDraggingRef.current = true)}
                    onMouseUp={() => (isDraggingRef.current = false)}
                    className={`block w-full mt-7 lg:mx-auto ${rangeClassName ?? ''}`}
                    onChange={rangeOnChangeHandler}
                    value={range}
                    min={0}
                    // set small step to have smooth animation
                    step="0.1"
                    max={rangeScale}
                />
            )}
        </>
    )
}

export default CarouselWithSlider
