import * as React from 'react'
import Glider from 'glider-js'
import 'glider-js/glider.min.css'
import styles from './style.module.css'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

type Ref = React.MutableRefObject<unknown>
interface IProps {
    children: React.ReactNode
    className?: string
    dots?: string | HTMLElement
    onSlideVisible?: (event: CustomEvent) => void
    slidesToShow?: number | 'auto'
    responsive?: Record<string, unknown>[]
    onScroll?: (e: Event) => void
    itemWidth?: number
    exactWidth?: boolean
    snap?: boolean
    prevArrow?: string | HTMLElement
    nextArrow?: string | HTMLElement
    onAnimationComplete?: (event: CustomEvent) => void
    animating?: boolean
}

const Carousel = (
    {
        children,
        className = '',
        onSlideVisible,
        dots,
        slidesToShow = 1,
        responsive,
        onScroll,
        itemWidth,
        exactWidth,
        snap = true,
        prevArrow,
        nextArrow,
        onAnimationComplete,
        animating,
    }: IProps,
    forwardedRef,
): React.ReactElement => {
    const ref = React.useRef<HTMLDivElement>(null)
    const gliderRef = React.useRef(null)

    React.useEffect(() => {
        return () => {
            gliderRef.current?.destroy()
        }
    }, [])

    useIsomorphicLayoutEffect(() => {
        // define new glider
        gliderRef.current = new Glider(ref.current, {
            slidesToShow,
            draggable: true,
            dots,
            itemWidth,
            exactWidth,
            skipTrack: true, // without skipTrack: true, glider js breaks with react
            responsive,
            arrows: {
                prev: prevArrow,
                next: nextArrow,
            },
            eventPropagate: true,
            scrollPropagate: true,
        })
    }, [dots, slidesToShow, responsive, itemWidth, exactWidth, prevArrow, nextArrow])

    React.useEffect(() => {
        if (!onSlideVisible) {
            return
        }

        const gliderRef = ref

        gliderRef.current?.addEventListener('glider-slide-visible', onSlideVisible)
        return () => {
            gliderRef.current?.removeEventListener('glider-slide-visible', onSlideVisible)
        }
    }, [onSlideVisible])

    React.useEffect(() => {
        if (!onAnimationComplete) {
            return
        }

        const gliderRef = ref

        gliderRef.current?.addEventListener('glider-animated', onAnimationComplete)
        return () => {
            gliderRef.current?.removeEventListener('glider-animated', onAnimationComplete)
        }
    }, [onAnimationComplete])

    React.useEffect(() => {
        if (typeof onScroll !== 'function') {
            return
        }

        const gliderRef = ref.current

        gliderRef.addEventListener('scroll', onScroll)

        return () => {
            gliderRef.removeEventListener('scroll', onScroll)
        }
    }, [onScroll])

    // expose glider methods to parent component
    React.useImperativeHandle(forwardedRef, () => gliderRef.current)

    return (
        <div className={`glider-contain h-full ${styles.container} ${className}`}>
            <div
                ref={ref}
                className={`glider h-full ${snap ? (animating ? styles['snap-animating'] : styles['snap']) : ''}`}
            >
                {/* element with class gidler-track needed for glider to init carousel
                 * remove this if skipTrack option is set to false
                 */}
                <div className="glider-track">{children}</div>
            </div>
        </div>
    )
}

export default React.forwardRef<Ref, IProps>(Carousel)
